import Auth from '@/components/auth/routes';
import Errors from '@/router/routes/errors';
import Settings from '@/components/account-settings/routes';
import Onboarding from '@/components/onboarding/routes';
import Adcard from '@/components/adcard/routes';
import Adpay from '@/components/adpay/routes';
import TempLinks from '@/router/routes/temp-links';
import Dashcash from '@/components/dashcash/routes';

const routeCollections = [
  Auth,
  Settings,
  Onboarding,
  Adcard,
  Adpay,
  Dashcash,
  TempLinks,
  {
    path: '',
    redirect: '/card',
  },
  // To ensure your imported routes work, always ensure Errors comes last
  Errors
];

export default routeCollections.flat();

// No need to import Vue here
import VueRouter from 'vue-router';
import routes from './routes';
import api from '../utils/api-services';
import { authStore } from '../stores/auth';
import { customerStore } from '../stores/customer';
import { getClient, flagKeys } from '@/utils/flags';

const router = new VueRouter({
  mode: 'history',
  routes
});

const openRoutes = [
  'login',
  'register',
  'forgetPassword',
  'resetPassword',
]

router.beforeEach(async (to, from, next) => {

  const auth = authStore();
  const customer = customerStore();

  const protectedRoute = to.matched.some(record => record.meta.protected);
  const adminOnlyRoute = to.matched.some(record => record.meta.adminOnly);
  const ownerOnlyRoute = to.matched.some(record => record.meta.ownerOnly);

  try {
    if (!auth.isLoaded) {
      const {
        data: { data }
      } = await api.fetchUserDetails();

      auth.setUser(data, true);

      if(auth.user?.account.approved) {
        try {
          const customerData = await api.getCustomerDeposits();
          const customerTreasuryDeposits = customerData.data.deposits;

          customer.set({
            deposits: customerTreasuryDeposits
          });
        }
        catch (error) {
          customer.set({
            deposits: []
          });
          console.error('Failed to get customer deposits', error);
        }
      }
    }


    const isAdmin = auth.user.is_admin;
    const accountId = auth.user.account_id;
    let rewardsEngineUIEnabled = false;

    try {
      const ffClient = getClient(accountId);
      rewardsEngineUIEnabled = await ffClient.getValueAsync(flagKeys.rewardsEngineUIEnabled, false);
    } catch (error) {
      console.error(`Failed to get ${flagKeys.rewardsEngineUIEnabled} flag`, error);
      rewardsEngineUIEnabled = null;
    }

    if(!protectedRoute) {
      next();
      return;
    }

    if(adminOnlyRoute && !auth.user.is_admin) {
      next('/');
    }

    if(ownerOnlyRoute && !auth.user.is_owner) {
      next('/');
    }

    if (!to.fullPath.startsWith('/email/verify') && !auth.user.email_verified_at) {
      next('/email/verify');
    }
    else if (!to.fullPath.startsWith('/apply') && !auth.user?.account?.approved) {
      next('/apply');
    }
    else if (!to.fullPath.startsWith('/2fa/setup') && !auth.user['2faEnabled'] && auth.user.account.approved) {
      next('/2fa/setup');
    }
    else if ((isAdmin || rewardsEngineUIEnabled) && (to.name === 'RewardsRedemption' || to.name === 'RewardsEarning')) {
      next('/card/rewards/overview');
    }
    else if ((!isAdmin && !rewardsEngineUIEnabled) && (to.name === 'RewardsAccrued' || to.name === 'RewardsTerms')) {
      next('/card/rewards/overview');
    }
    else {
      next();
    }


  } catch (error) {
    if (401 === error?.response?.status) {
      auth.setUser(null, false);
      if (openRoutes.indexOf(to.name) === -1) {
        next('/login' );
      }
      else {
        next();
      }
    } else  {
      next();
    }
  }
});

// // This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta?.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta?.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta?.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta?.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta?.title;
  }
  // Handle navigation duplication for router push (Globally)
  const originalPush = VueRouter.prototype.push;
  VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => {
      if (VueRouter.isNavigationFailure(err)) {
        // resolve err
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;

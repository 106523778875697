import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_BFF_BASE_URL,
  headers: {
    'Accept': 'application/json'
  },
  withCredentials: true
});

export default http;

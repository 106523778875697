import { getDomainTitle } from '@/utils/get-domain-title';
const domainTitle = getDomainTitle();

export default [
  {
    path: '/settings',
    meta: {
      protected: true
    },
    component: () => import('@/components/account-settings'),
    children: [
      {
        path: 'personal',
        name: 'PersonalSettings',
        component: () =>
          import('@/components/account-settings/personal-settings.vue'),
        meta: {
          title: `Personal Details - ${domainTitle}`
        }
      },
      {
        path: '/settings',
        redirect: 'personal'
      },
      {
        path: 'users',
        name: 'UsersSettings',
        component: () =>
          import('@/components/account-settings/users-settings.vue'),
        meta: {
          title: `User Details - ${domainTitle}`,
          ownerOnly: true
        }
      },
      {
        path: 'business',
        name: 'BusinessSettings',
        component: () =>
          import('@/components/account-settings/business-settings.vue'),
        meta: {
          title: `Business Details - ${domainTitle}`
        }
      },
      {
        path: 'underwriting',
        name: 'UnderwritingSettings',
        component: () =>
          import('@/components/account-settings/underwriting-settings.vue'),
        meta: {
          title: `Underwriting Details - ${domainTitle}`
        }
      },
      {
        path: 'documents',
        name: 'DocumentsSettings',
        meta: {
          protected: true,
          ownerOnly: true,
          title: `Documents - ${domainTitle}`
        },
        component: () =>
          import('@/components/account-settings/documents/tasks-list.vue')
      },
      {
        path: 'payments',
        name: 'PaymentsSettings',
        component: () =>
          import('@/components/account-settings/payments-settings.vue'),
        meta: {
          title: `Payments Details - ${domainTitle}`
        }
      },
      {
        path: 'connected-services',
        name: 'ConnectedServicesSettings',
        component: () =>
          import(
            '@/components/account-settings/connected-services-settings.vue'
          ),
        meta: {
          title: `Connected Services - ${domainTitle}`
        }
      },
      {
        path: 'one-click-credit',
        name: 'OneClickCreditSettings',
        component: () =>
          import('@/components/account-settings/one-click-credit-settings.vue'),
        meta: {
          title: `One-Click Credit - ${domainTitle}`
        }
      },
      {
        path: 'integrations',
        name: 'IntegrationsSettings',
        component: () =>
          import('@/components/account-settings/integrations-settings.vue'),
        meta: {
          title: `Integrations - ${domainTitle}`
        }
      },
      {
        path: 'reset-password',
        name: 'ResetPasswordSettings',
        component: () =>
          import('@/components/account-settings/reset-password-settings.vue'),
        meta: {
          title: 'Reset Password'
        }
      },
      {
        path: 'reset-password',
        name: 'ResetPasswordSettings',
        component: () =>
          import('@/components/account-settings/reset-password-settings.vue'),
        meta: {
          title: 'Reset Password'
        }
      }
    ]
  }
];
